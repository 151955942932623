<template>
    <div class="o-layout o-layout--medium">
        <div class="container-fluid">
            <div class="row" v-if="!isOnCourseCreatePage">
                <div class="col-12">
                    <recess-button
                        :title="BUTTON_TEXT.goBack"
                        icon="back"
                        variant="variant4"
                        class="qa-go-back-button"
                        url="/portfolio/overview"
                    />
                </div>
            </div>

            <div class="row justify-content-between">
                <div class="col-10">
                    <h2 class="u-text-wrap">{{ this.course.name }}</h2>
                </div>

                <div v-if="!isOnCourseCreatePage && !isOnCourseDuplicatePage" 
                    class="col-2"
                >
                    <div v-if="!course.deleted"
                        class="d-flex justify-content-end" 
                    >
                        <recess-tooltip>
                            <template slot="content"> {{ PROVIDER.Portfolio.CourseEdit.DuplicateIcon.Tooltip }}</template>
                            <span class="c-icon c-icon__copy qa-copy-details-course" @click="copyCourseHandler(course.id)"> </span>
                        </recess-tooltip>
                        <span class="c-icon c-icon__bin qa-delete-course ml-4" @click="showDeleteCourseModal(getCourseId)"></span>
                    </div>
                </div>

                <div class="col-12">
                    <h4 class="mt-2">{{ this.course.externalId }}</h4>
                </div>
            </div>

            <div class="row">
                <tab-navigation
                    page-name="portfolio"
                    class="col-12"
                    :route-params-id="$route.params.id"
                    :navigation-tabs="navigationTabs"
                    :active-tab="activeTab"
                    :has-errors="disableTabLink"
                    @setTab="setActiveTab"
                />
            </div>

            <div class="row">
                <div
                    v-if="course"
                    class="col-12"
                >
                    <keep-alive>
                        <component
                            :is="activeTab"
                            :planning-and-invoices-data="{
                                source: course.source,
                                productType: course.productType,
                                learningMethod: course.learningMethod,
                                subsidies: course.subsidies,
                                crebo: course.crebo,
                                croho: course.croho
                            }"
                            :startMomentsListOptions="{
                                setPaginationOptions: setPaginationOptions,
                                setTableOptions: setTableOptions,
                                filter: filter,
                                selectedFilters: selectedFilters,
                                copyID: copyID,
                                cityOptions: cityOptions
                            }"
                            :active-tab="activeTab"
                            @submitForm="submitCourseEdit"
                            @createCourse="postCourse"
                            @triggerProgressChange="triggerProgressChange"
                            @setStartMoments="setStartMoments"
                            @setInvoicesCourseValue="setInvoicesCourseValue"
                            @tabChange="setActiveTab"
                            @updateStartmomentList="updateStartmomentList"
                            @updateFilters="updateFiltersHelper"
                        />
                    </keep-alive>
                </div>
            </div>
        </div>

        <edit-duplicated-course-popup-component
            v-if="isEditDuplicatedCoursesVisible"
            :course-id="getCourseId"
            :is-modal-visible="isEditDuplicatedCoursesVisible"
            @close="closeEditDuplicatedCourse"
        />

        <delete-course-popup-component
            v-if="isDeleteCourseModalVisible"
            :has-course-details="hasCourseDetails"
            :is-modal-visible="isDeleteCourseModalVisible"
            @close="closeDeleteCourseModal"
        />
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { getItemById, getItems, putItem, postItem, getEnumList, getItemsFromSearchIndex } from '@/../../shared/api/SharedClient'
import { BUTTON_TEXT, API_CALL_URL_PATHS, PAGINATION } from '@/../../shared/constants/constantsGeneral.json'
import {
    setCourseNotificationError,
    updateInhoundBeforeSave,
    setCountryVatPercentageOptions,
    formatStartMomentsBeforeSave,
    sortStartMoments
} from '@/utils/vuexFunctionHelper'
import {
    navigationTabs,
    updateEnumsBeforeSave,
    setGeneralInfoData,
    setContentData,
    setPlanningData,
    setCategoriesData,
    setDiplomaData,
    getValuesFromObject,
    emptyCourse
} from './courseEditHelpers'

import { deleteStartmoment, postStartmoment, putStartmoment, patchStartmoment, starmomentHasErrorsIncompany } from '@/utils/startmomentHelper'
import { deleteSegment, postSegment } from '@/utils/productSubsegmentsHelper'
import { shallowEqual } from '@/../../shared/utils/validationHelpers.js'

import { assortedProductSuccessAction } from '@/../../shared/constants/validationMessageHelper'

import {combineEventDateTime, formatToUtcBeforeSave, formatFromUtcToLocalDate, formatStartmomentsFromUtcToLocalDate,
     formatCutOfDateToUtcBeforeSave, formatStartmomentCutOfDateToUtcBeforeSave, formatDuplicatedCourse, toDateWithoutHours } from '@/utils/dateTimeHelper'

import { mapGetters } from 'vuex'

const TabNavigation = () => import('@/pages/Portfolio/CourseEdit/TabNavigation.vue')
const DeleteCoursePopupComponent = () => import('@/components/molecules/DeleteCoursePopupComponent')
const EditDuplicatedCoursePopupComponent = () => import('@/components/molecules/EditDuplicatedCoursePopupComponent')

const EditGeneralInfo = () => import('../../../pages/Portfolio/CourseEdit/EditGeneralInfo')
const EditPlanning = () => import('../../../pages/Portfolio/CourseEdit/EditPlanning')

const EditInvoiceItem = () => import('../../../pages/Portfolio/CourseEdit/EditInvoiceItem')
const EditCategories = () => import('../../../pages/Portfolio/CourseEdit/EditCategories')
const EditDiploma = () => import('../../../pages/Portfolio/CourseEdit/EditDiploma')
const EditContent = () => import('../../../pages/Portfolio/CourseEdit/EditContent')

const renameKeys = (keysMap, obj) =>
	Object.keys(obj).reduce(
		(acc, key) => ({
			...acc,
			...{ [keysMap[key] || key]: obj[key] }
		}),
		{}
	)

export default {
    name: 'CourseDetails',
    components: {
        TabNavigation,
        DeleteCoursePopupComponent,
        EditDuplicatedCoursePopupComponent,
        'course-algemeen': EditGeneralInfo,
        'course-categorieen': EditCategories,
        'course-inhoud': EditContent,
        'course-diploma': EditDiploma,
        'course-planning': EditPlanning,
        'course-investering': EditInvoiceItem
    },
    provide() {
        const generalInfoCourseData = {}
        const contentCourseData = {}
        const planningCourseData = {}
        const categoriesCourseData = {}
        const diplomaCourseData = {}

        Object.defineProperty(categoriesCourseData, "categoriesData", {
            enumerable: true,
            get: () => this.categoriesData
        })
        Object.defineProperty(categoriesCourseData, "segmentsOptions", {
            enumerable: true,
            get: () => this.segmentsOptions
        })

        Object.defineProperty(generalInfoCourseData, "generalInfoData", {
            enumerable: true,
            get: () => this.generalInfoData
        })

        Object.defineProperty(contentCourseData, "contentData", {
            enumerable: true,
            get: () => this.contentData
        })

        Object.defineProperty(planningCourseData, "planningData", {
            enumerable: true,
            get: () => this.planningData
        })

        Object.defineProperty(diplomaCourseData, "diplomaData", {
            enumerable: true,
            get: () => this.diplomaData
        })

        return {
            generalInfoCourseData,
            contentCourseData,
            planningCourseData,
            categoriesCourseData,
            diplomaCourseData
        }
    },
    data() {
        const paginationOptions = [
			{ displayText: '5', value: 5 },
			{ displayText: '10', value: 10 },
			{ displayText: '25', value: 25 },
			{ displayText: '50', value: 50 },
			{ displayText: '100', value: 100 },
			{ displayText: '250', value: 250 }
		]
        return {
            PROVIDER,
            BUTTON_TEXT,
            iconFont: 'keyboard_arrow_left',
            isDeleteCourseModalVisible: false,
            navigationTabs,
            courseInAssortment: false,
            duplicatedCourse: {},
            isEditDuplicatedCoursesVisible: false,
            activeTab: 'course-algemeen',
            generalCourseDetails: {},
            course: {},
            invoiceItems: {},
            invoiceData: {},
            generalInfoData: {},
            contentData: {},
            planningData: {},
            categoriesData: {},
            diplomaData: {},
            selectedSegments: [],
            initialSelectedSegments: [],
			productSubSegments: [],
			segmentsOptions: [
				{
					displayText: PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SegmentsOptions.InitialOption,
					value: ''
				}
			],
            subsidiesOptions: [],
            disableTabLink: false,
            initialStartmoments: [],
            filter: {
                filter: `courseId eq '${this.$route.params.id}' and inactive eq false`,
                top:  25,
                skip: 0,
                facets: ["city"]
            },
            setPaginationOptions: {
				showTopPagination: true,
				showBottomPagination: true,
				currentPage: 1,
				selectOptions: paginationOptions,
                totalCount: 0,
				defaultTotalItemsPerPage: paginationOptions[2].value,
				itemsPerPageFromUrlQuery: paginationOptions[2].value,
				showSelectOption: true,
				selectOptionsLabel: PAGINATION.selectOptionsLabel,
				paginationShowLabel: PAGINATION.paginationShowLabel,
				paginationOfLabel: PAGINATION.paginationOfLabel,
                scroll: false
			},
            setTableOptions: {
				setCellSizes: true,
                title: PROVIDER.Portfolio.CourseEdit.PlanningTab.StartmomentTitle
			},
            selectedFilters: {
                startMomentStatus: 'active',
                startDate: '',
                city: ''
            },
            cityOptions: [{
                displayText: PROVIDER.Portfolio.CourseEdit.PlanningTab.AllLocations,
                value: ''
            }],
            copyID: ''
        }
    },
    computed: {
         ...mapGetters('providerModule', ['getProviderId']),
        getCourseId() {
            return this.$route.params.id
        },
        isExpiredCourse() {
            const currentDate = new Date().toJSON().slice(0, 10)
            return (
                this.course.deleted === true ||
                (this.course.endDate != null && this.course.endDate < currentDate)
            )
        },
        setGoBackLink() {
            const checkParamsOnStorage = localStorage.getItem('setPorftolioBackRoute')

            if(checkParamsOnStorage) return +checkParamsOnStorage

            return 1
        },
        isOnCourseCreatePage() {
            return this.$route.name === 'course-create'
        },
        isOnCourseDuplicatePage() {
            return this.$route.name === 'course-duplicate'
        },
    },
    watch: {
        getProviderId(newValue) {
           this.course.providerId = newValue
        },
        activeTab: async function (currentTab, oldTab) {
            if (this.$route.name === 'course-details') {
                if (currentTab == 'course-investering') {
                    this.filter = {
                        filter: `courseId eq '${this.$route.params.id}' and inactive eq false`,
                        top:  9999,
                        skip: 0,
                        facets: ["city"]
                    }

                    this.selectedFilters = {
                        startMomentStatus: 'active',
                        startDate: '',
                        city: ''
                    }

                    await this.getStartmoments()
                }
                            
                if (this.filter.top === 9999 && oldTab == 'course-investering') {
                    this.course.learningMethod.planning.startMoments = []
                    this.filter.top = 25;
                    this.filter.skip = 0;
                    await this.getStartmoments()
                }
            }
        }
    },
     async created() {
        window.addEventListener('beforeunload', this.pageLeave())

        if(this.isOnCourseCreatePage) {            
            this.course = JSON.parse(JSON.stringify(emptyCourse))
            this.parseTabsData(this.course)
        }

        // Course duplication 
        if (this.isOnCourseDuplicatePage) {
            const parsedDuplicateCourse = JSON.parse(localStorage.getItem('duplicatedCourse'))
            const parsedSelectedAccounts = JSON.parse(localStorage.getItem('selectedAccounts'))
            const duplicatedID = JSON.parse(localStorage.getItem('duplicatedId'))
            
            // Assign the duplicated data to the course
            this.course = parsedDuplicateCourse
            this.copyID = duplicatedID.courseId;

            this.filter.filter = `courseId eq '${this.copyID}' and inactive eq false`;

            // Assign the selected accounts
            if(parsedSelectedAccounts.length > 0) this.course.selectedAccounts = parsedSelectedAccounts

            // Because the way we are sending data, some formatting is necessary
            formatDuplicatedCourse(this.course)

            // Parse the data
            this.parseInitialData(this.course)
        }
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.pageLeave())
        localStorage.removeItem('duplicatedCourse')
        localStorage.removeItem('selectedAccounts')
        localStorage.removeItem('duplicatedId')
    },
    mounted() {
        // Code that will run only after the entire view has been rendered
        // We need this otherwise the token will not be sent in the request header through axios
        this.$nextTick(() => {
            this.getAllData() 
        })
    },
    methods: {
        resetTabStates() {
            this.navigationTabs.forEach(tab => {
                tab.isValid = false
                tab.isDisabled = false
                tab.hasErrors = false
            })
        },
        pageLeave() {
            if (this.$route.query.tab !== this.activeTab) {
                if (this.$route.params && this.$route.params.id  && this.$route.params.isDuplicateCourseEvent) {
                    return
                }
                if (this.isOnCourseCreatePage) {
                    this.activeTab = 'course-algemeen'
                    this.setQuery(this.activeTab)
                    return
                }

                if (!this.$route.query.tab && this.$route.fullPath !== '/portfolio/overview') {
                    this.activeTab = 'course-algemeen'
                    this.setQuery(this.activeTab)
                    return
                }

                this.activeTab = this.$route.query.tab
            }
        },
        allowedQueries() {
            const queryNames = [
                'course-algemeen',
                'course-categorieen',
                'course-inhoud',
                'course-diploma',
                'course-planning',
                'course-investering'
            ]
            return queryNames.includes(this.$route.query.tab)
        },
        getProviderID() {
            if(!this.isOnCourseDuplicatePage) this.course.providerId = this.getProviderId
        },
        async submitCourseEdit() {
            // we make a copy and change the date before posting so the visual part to the user
            // doesn't change and in case of a error the start and end date are still ok
            const subsidiesOptions = [...this.subsidiesOptions]
        
            const copyCourse = JSON.parse(JSON.stringify(this.course))

            if (copyCourse.productType === 'Incompany') {
                const hasErrors = starmomentHasErrorsIncompany(copyCourse, this.course)

                if(hasErrors) {
                    const toastNotification = {
                        type: 'error',
                        message: "Startmoment: Go/no-go datum ligt in het verleden of heeft een verkeerd formaat"
                    }

                    this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })

                    return
                }

            } 
          
            combineEventDateTime(copyCourse)

            updateEnumsBeforeSave(copyCourse, subsidiesOptions)

            updateInhoundBeforeSave(copyCourse)

            // format accreditation dates, course startDate & endDate that is accepted by the server
            formatToUtcBeforeSave(copyCourse)

            await this.updateCourse(copyCourse)
            this.updateProductSubsegments(copyCourse)
            this.updateStartMoments(copyCourse)

            if (!this.isOnCourseCreatePage) {
                setTimeout(async () => {
                    await this.getStartmoments()
                    this.$forceUpdate()
                }, 2000) // Waiting for index to upload
            }

        },
        async postCourse() {
            this.getProviderID()

            const subsidiesOptions = [...this.subsidiesOptions]

            // we make a copy and change the date before posting so the visual part to the user
            // doesn't change and in case of a erropr the start and end date are still ok
            const copyCourse = JSON.parse(JSON.stringify(this.course))

            delete copyCourse.source // delete source before posting as server doesnt allow to specific source in UI but we need this for BTW validation
            delete copyCourse.segments

            combineEventDateTime(copyCourse)

            updateInhoundBeforeSave(copyCourse)

            formatToUtcBeforeSave(copyCourse)
            formatCutOfDateToUtcBeforeSave(copyCourse)
            copyCourse.learningMethod.planning.startMoments.forEach(startmoment => {
                formatStartMomentsBeforeSave(startmoment)
            })

            updateEnumsBeforeSave(copyCourse, subsidiesOptions)

            await postItem(
                process.env.VUE_APP_PROVIDER_API_URL,
                API_CALL_URL_PATHS.courses,
                copyCourse,
                false
            ).then((response) => {
                this.$bus.$emit('triggerProductTargetAccounts', response.id)
                this.createCourseSegments(response.id)

                const toastNotification = {
                    type: 'success',
                    message: assortedProductSuccessAction('aangemaakt')
                }

                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })

            setTimeout(() => {
                    this.$router.push({
                        name: 'course-details',
                        params: { id: response.id },
                        query: { tab: 'course-algemeen'}
                    }).then(() => { this.$router.go()})

                }, 3000)

            }).catch((error) => {

                const errorMessages = setCourseNotificationError(error)

                for (const errorMessage of errorMessages) {
                    const toastNotification = {
                        type: 'error',
                        message: errorMessage.message
                    }

                    this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })
                }
            })
        },
        async createCourseSegments(courseId) {
			const segmentsToQueue = await this.getSelectedSubSegments(courseId)
			await this.postProductSubSegment(segmentsToQueue, courseId)
		},
        async getSelectedSubSegments(courseId) {
			const selectedSubSegments = []
			const productReference = `course:${courseId}`

            if(!this.isOnCourseDuplicatePage) {
                this.course.segments.reduce((accSegments, segment) => {
				const segmentOptionsSelectedValues = segment.filteredSubSegmentOptions?.filter(filteredSubSegmentOption => {
					return segment.subSegments.some(item => {
						return filteredSubSegmentOption.value === item
					})
				})
                
				segmentOptionsSelectedValues?.forEach(segmentOptionsSelectedItem => {
					selectedSubSegments.push({
						subSegmentId: segmentOptionsSelectedItem.id,
						productReference
					})
				})

				return accSegments
			    }, [])
            }
            else {
                this.course.segments.reduce((accSegments, segment) => {
                    segment.subSegments.forEach(subSegment => {
                        selectedSubSegments.push({
                        subSegmentId: subSegment.id,
                        productReference
                    })
                })

                    return accSegments
            }, [])

        }
			return selectedSubSegments
		},
        async postProductSubSegment(newProductSubSegment, courseId) {
			if (newProductSubSegment.length === 0 && !courseId) return

			await postItem(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.productSubSegments, newProductSubSegment, false)
				.catch((error) => {
					console.error('Something went wrong while posting the sub segment', error)
				})
		},
        async getAllData() {
            this.resetTabStates()
            this.getProviderID()
            this.getSubsidies()
            await this.getSegmentsOptions()
            await this.setSubSegmentOptions()

            if(!this.isOnCourseCreatePage && !this.isOnCourseDuplicatePage) {
                await this.getCourse()
                this.$route.query?.tab === 'course-planning' ? await this.getStartmoments() : this.getStartmoments()
                await this.getProductSubSegments()
                this.setSelectedSegments()
            }
        },
        triggerProgressChange(updatedCourseData) {
            if (updatedCourseData) {
                const newData = { ...updatedCourseData }
                let courseContent = { ...this.course }
                const updatedCourseDataKeys = Object.keys(newData)
                updatedCourseDataKeys.forEach((key) => {
                    if (key !== 'learningMethod') {
                        courseContent[key] = newData[key]
                    } else {
                        courseContent.learningMethod = updatedCourseData.learningMethod
                    }
                })

                this.course = courseContent
            }
        },
        setActiveTab(tabPath) {
            let path
            if (typeof tabPath === 'object') {
                path = tabPath.tabTo
                //Make tab valid since it already passed all validation checks
                this.navigationTabs[tabPath.currentTabIndex].isValid = true
            }
            else {
                path = tabPath
            }

            if(this.activeTab === path) return

            this.activeTab = path

            this.setQuery(path)
        },
        setQuery(tabPath) {
            this.$router.replace({ query: { tab: tabPath } })
        },
        parseInitialData() {
            if (!this.isOnCourseDuplicatePage) formatFromUtcToLocalDate(this.course)
            if (!this.isOnCourseDuplicatePage) formatStartmomentsFromUtcToLocalDate(this.course)

            setCountryVatPercentageOptions(this.course.learningMethod.costs.details[0].invoiceItems)

            this.course.competences = getValuesFromObject(this.course.competences)
            this.course.competencesRijksoverheid = getValuesFromObject(this.course.competencesRijksoverheid)
            this.course.targetSector = getValuesFromObject(this.course.targetSector)
            this.course.targetSectorRijksoverheid = getValuesFromObject(this.course.targetSectorRijksoverheid)
            this.course.subsidies = getValuesFromObject(this.course.subsidies)
            this.parseTabsData(this.course)
        },
        parseTabsData(course) {
            this.generalInfoData = setGeneralInfoData(course)
            this.contentData = setContentData(course)
            this.categoriesData = setCategoriesData(course)
            this.diplomaData = setDiplomaData(course)
        },
        async getStartmoments() {
            if (this.filter.top == 9999 && this.activeTab != 'course-investering') {
                return
            }
            try {
                const response = await getItemsFromSearchIndex(
                    process.env.VUE_APP_PROVIDER_V2_API_URL,
                    API_CALL_URL_PATHS.startMoments,
                    API_CALL_URL_PATHS.search, 
                    this.filter
                )
             
                if (!response.results) return

                const startmoments = []
                response.results.forEach(startmoment => {
                    startmoments.push(startmoment.document)
                })

                startmoments.forEach(startmoment => {
                    startmoment.id = startmoment.startMomentId
                })
                
                if (!this.course.learningMethod) {
                    return
                }
                this.course.learningMethod.planning.startMoments = startmoments
                formatStartmomentsFromUtcToLocalDate(this.course)
                sortStartMoments(this.course.learningMethod.planning.startMoments)

                // Copy original startmoments after all formatting
                this.initialStartmoments = JSON.parse(JSON.stringify(this.course.learningMethod.planning.startMoments))
                sortStartMoments(this.initialStartmoments)
          
                this.planningData = setPlanningData(this.course)
                this.setPaginationOptions.totalCount = response.count

                if (response.facets.city && !this.selectedFilters.city) {
                    let allOption = {
                        displayText: PROVIDER.Portfolio.CourseEdit.PlanningTab.AllLocations,
                        value: ''
                    }

                    let cities = response.facets.city.map(item => ({
                        value: item.value,
                        displayText: item.value
                    }));

                    this.cityOptions = [allOption, ...cities]
                }
                this.$forceUpdate()
            } catch (error) {
                console.error('Something went wrong while retrieving courses', error)
            }
        },
        async updateFiltersHelper(filter, value) {
            this.selectedFilters[filter] = value;
            this.updateFilters(); 
        },
        async updateFilters() {
            const { startMomentStatus, startDate, city } = this.selectedFilters;

            // Base filter conditions
            let filters = `courseId eq '${this.$route.params.id || this.copyID}'`;

            // Add startMomentStatus filter
            if (startMomentStatus) {
                let status = startMomentStatus === 'active' ? false : true;
                filters += ` and inactive eq ${status}`;
            }

            // Add startDate filter
            if (startDate) {
                let formattedStartDate = toDateWithoutHours(startDate);
                filters += ` and startDate eq ${formattedStartDate}`;
            }

            // Add location filter
            if (city) {
                filters += ` and city eq '${city}'`;
            }

            // Update the filter object
            this.filter.filter = filters;
            this.filter.skip = 0;

            // Reset pagination options
            this.setPaginationOptions.currentPage = 0;

            await this.getStartmoments()
        },
        async updateStartmomentList({ currentPage, itemsPerPage }){
            this.setPaginationOptions.itemsPerPageFromUrlQuery = itemsPerPage
			this.setPaginationOptions.currentPage = currentPage

			this.filter.top = itemsPerPage
			this.filter.skip = currentPage ? currentPage * itemsPerPage - itemsPerPage : currentPage;

            await this.getStartmoments()
        },
        async getCourse() {
            try {
                const response = await getItemById(
                    process.env.VUE_APP_PROVIDER_V2_API_URL,
                    API_CALL_URL_PATHS.courses,
                    this.getCourseId,
                    'productSubSegments',
                    false
                )

                if (!response) return

                response.segments = response.embedded.segments
                delete response.embedded.segments

                this.course = response
                this.course.learningMethod.planning = { startMoments: [] }

                this.courseInAssortment = this.course.count > 0
                this.parseInitialData()
            } catch (error) {
                console.error('Something went wrong while retrieving courses', error)
            }
        },
        showDeleteCourseModal(courseId) {
            this.hasCourseDetails = {
                courseId,
                isInCourseDetailsPage: true,
                isInAssortment: this.courseInAssortment
            }
            this.isDeleteCourseModalVisible = true
        },
        closeDeleteCourseModal() {
            this.isDeleteCourseModalVisible = false
        },
        copyCourseHandler() {
            this.isEditDuplicatedCoursesVisible = true
        },
        closeEditDuplicatedCourse() {
            this.isEditDuplicatedCoursesVisible = false
        },
        // segments methods
		async getProductSubSegments() {
			if (!this.course.id) return
			try {
				const response = await getItems(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.productSubSegments, 1, 99999, null, `productReference eq 'course:${this.course.id}'`, false)
				if (!response) return

				this.productSubSegments = response
			} catch (error) {
				console.error('Something went wrong while retrieving product sub segments list', error)
			}
		},
		async getSegmentsOptions() {
			try {
				const response = await getItems(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.segments, 1, 99999, '', '', false, 'subSegments')
				if (!response) return
				const keysMap = {
					name: 'value',
					description: 'displayText'
				}

				const renamedResult = response.items.map((obj) => renameKeys(keysMap, obj))
				this.segmentsOptions = renamedResult
			} catch (error) {
				console.error('Something went wrong while retrieving segment enum list', error)
			}
		},
		async setSubSegmentOptions(arrayToSet = this.segmentsOptions) {
			await arrayToSet.reduce(async (acc, segment, segmentIndex) => {
				await this.getSubSegmentOptions(segment, segmentIndex, arrayToSet)
				return acc
			}, [])
		},
		async getSubSegmentOptions(segment, segmentIndex, arrayToSet = this.segmentsOptions) {
			if (segment) {
				const keysMap = {
					name: 'value',
					description: 'displayText',
                    displayValue: 'displayText'
				}

				const renamedResult = arrayToSet[segmentIndex].subSegments.map((obj) => renameKeys(keysMap, obj))
                arrayToSet[segmentIndex].filteredSubSegmentOptions = renamedResult
			}
		},
		async getSubsidies() {
			try {
				const response = await getEnumList(
					process.env.VUE_APP_PROVIDER_API_URL,
					API_CALL_URL_PATHS.enumerations,
					'Subsidy',
					null,
					null,
					false
				)

				if (!response) return

				this.subsidiesOptions = response.reduce((accumulator, subsidie) => {
					// if (subsidie.value === 'NLContinuesToLearn') {
					// 	subsidie.disabled = true
					// }
                    // https://evident-digital.atlassian.net/browse/ED-19356
					// TODO this is to be added back after Dec 31st  2022

					return [...accumulator, subsidie]
				}, [])
			} catch (error) {
				console.error('Something went wrong while retrieving subsidies', error)
			}
		},
		setSelectedSegments() {
			const productSubSegmentCopy = JSON.parse(JSON.stringify(this.productSubSegments))

            if (productSubSegmentCopy.items?.length <= 0) {
                this.addSegmentItem()
                return
            }

            this.segmentsOptions.forEach(segment => { 
                const segmentCopy = JSON.parse(JSON.stringify(segment))
                segmentCopy.subSegments = []

                let hasSelectedSegment = false

                productSubSegmentCopy.items = productSubSegmentCopy.items.filter(productSubSegment => {
                    // since we know that if you have a segmentId on productSubSegment we don't have any subSegment selected
                    if (productSubSegment.segmentId === segment.id) {
                        hasSelectedSegment = true
                        return false
                    }

                    const segmentOptionsSelectedValues = productSubSegment.subSegmentId !== null ? segment.filteredSubSegmentOptions.filter(subSegmentOption => {
                        return productSubSegment.subSegmentId === subSegmentOption.id
                    }) : []


                    if (productSubSegment.subSegmentId !== null && segmentOptionsSelectedValues.length > 0) {

                            const selectedSubSegments = segmentOptionsSelectedValues.map(result => {
                                return {
                                    id: result.id,
                                    value: result.value
                                }
                            })

                            segmentCopy.subSegments = [...segmentCopy.subSegments, ...selectedSubSegments]

                            hasSelectedSegment = true
                            return false
                        }

                    return true
                })
                

                if (hasSelectedSegment) {
                    this.selectedSegments = [...this.selectedSegments, segmentCopy]
                    this.initialSelectedSegments = JSON.parse(JSON.stringify(this.selectedSegments))
                }
            })

            this.course.segments = JSON.parse(JSON.stringify(this.selectedSegments))
            this.categoriesData.segments = JSON.parse(JSON.stringify(this.selectedSegments))
		},
		addSegmentItem() {
			const emptySegmentItem = {
				value: null,
				displayText: null,
				subSegments: [],
				filteredSubSegmentOptions: []
			}
			this.categoriesData?.segments?.push(emptySegmentItem)
		},
        findAddedAndDeletedElements(list, originalList) {
            if (list === null || originalList === null) {
                return
            }

            const listIds = list.map(item => item.id)
            const originalIds = originalList.map(item => item.id)

            const added = list.filter(item => !originalIds.includes(item.id))
            const deleted = originalList.filter(item => !listIds.includes(item.id))

            return { added, deleted }
        },
        async updateProductSubsegments(copyCourse) {
            let promises = []
            const segmentList = JSON.parse(JSON.stringify(copyCourse.segments))

            // See if we have segments to POST
            const postSegmentList = segmentList.filter((segment) => {
                if (!this.initialSelectedSegments.id) return false
                const hasSegmentToPost = this.initialSelectedSegments.some(originalSegment => segment.id === originalSegment.id ) 
                
                return !hasSegmentToPost
            })

            // See if we have segments to DELETE
            const deleteSegmentList = this.initialSelectedSegments.filter((originalSegment) => {
                const hasSegmentToDelete = segmentList.some(segment => segment.id === originalSegment.id ) 
                return !hasSegmentToDelete
            })
            
            //Remove the deleted segment from initialSelectedSegments
            if(deleteSegmentList.length > 0) {
                deleteSegmentList.forEach(deletedSegment => {
                    const indexOfDeletedSegment = this.initialSelectedSegments.map(initialSegments => initialSegments.id ).indexOf(deletedSegment.id)
                    this.initialSelectedSegments.splice(indexOfDeletedSegment, 1)
                })
            }

            // See if we have SUBsegments to POST
            const subsegmentsToPost = []
            segmentList.forEach((segment, index) => {
                const initialList = this.initialSelectedSegments[index]?.subSegments ?? []
                const { added } = this.findAddedAndDeletedElements(segment.subSegments, initialList)

                if (added?.length > 0) {
                    subsegmentsToPost.push(...added)
                }
            })

            // See if we have SUBsegments to DELETE
            // If the segment is deleted all subsegments are also deleted
            // else check which subsegment was removed
            const subsegmentsToDelete = []
            if (deleteSegmentList.length > 0) {
                deleteSegmentList.forEach(deletedSegment => {
                    subsegmentsToDelete.push(...deletedSegment.subSegments)
                })
            }else {
                this.initialSelectedSegments.forEach((segment, index) => {
                    const list = segmentList[index]?.subSegments ?? []
                    const { deleted } = this.findAddedAndDeletedElements(list, segment.subSegments);
    
                    if (deleted?.length > 0) {
                        subsegmentsToDelete.push(...deleted)
                    }
                })
            }

            deleteSegmentList.forEach((segment)=> {
                const getProductSubSegmentId = this.productSubSegments.items.find(productSubsegment => productSubsegment.segmentId === segment.id)
                promises.push(deleteSegment(getProductSubSegmentId.id, this.$store))
            })

            subsegmentsToDelete.forEach((subsegment)=> {
                const getProductSubSegmentId = this.productSubSegments.items.find(productSubsegment => productSubsegment.subSegmentId === subsegment?.id)
                promises.push(deleteSegment(getProductSubSegmentId.id, this.$store))
            })

            await Promise.all(promises)

            promises = []

            const allPosts = []
            postSegmentList.forEach((segment) => {
                const mappedProductSubsegment = {
                    segmentId: segment.id,
                    subSegmentId: null,
                    productReference: `course:${this.getCourseId}`
                }

                allPosts.push(mappedProductSubsegment)
            })

            subsegmentsToPost.forEach(subsegment => {
                const mappedProductSubsegment = {
                    subSegmentId: subsegment.id,
                    segmentId: null,
                    productReference: `course:${this.getCourseId}`
                }

                allPosts.push(mappedProductSubsegment)

            })

            if(allPosts.length > 0) {
                promises.push(postSegment(allPosts, this.$store))
            }

            await Promise.all(promises)
        },
        async updateCourse(copyCourse) {
            // We need to do this so we can delete the planning without affecting the other calls 
            const copyCourseToUpdate = JSON.parse(JSON.stringify(copyCourse))
            // Don't send planning nor segments in put course
            delete copyCourseToUpdate.learningMethod.planning
            delete copyCourseToUpdate.segments
            copyCourseToUpdate.embedded = null

            await putItem(
                process.env.VUE_APP_PROVIDER_V2_API_URL,
                API_CALL_URL_PATHS.courses,
                copyCourseToUpdate.id,
                copyCourseToUpdate,
                false
            )
                .then(async () => {
                    this.$bus.emit('triggerProductTargetAccounts')
                
                    if (!this.isOnCourseCreatePage) {
                        this.selectedSegments = []
                        await this.getCourse()    
                        await this.getProductSubSegments()
                        this.setSelectedSegments()
                    }

                    const toastNotification = {
                        type: 'success',
                        message: assortedProductSuccessAction('bijgewerkt')
                    }

			        this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				        root: true
			        })

                })
                .catch((error) => {
                    const isOnEditCoursePage = true

                    const errorMessages = setCourseNotificationError(error, isOnEditCoursePage)

                    // eslint-disable-next-line no-restricted-syntax
                    for (const errorMessage of errorMessages) {
                        if (errorMessage.itemIndex !== null) {
                            this.course.learningMethod.planning.startMoments[errorMessage.itemIndex].hasErrors = true
                        }

                        const toastNotification = {
                            type: 'error',
                            message: errorMessage.message
                        }

			            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				            root: true
			            })
                    }
                })
        },
        async updateStartMoments(copyCourse) {
            let promises = []
            const course = JSON.parse(JSON.stringify(copyCourse))
            const startMomentList = JSON.parse(JSON.stringify(copyCourse.learningMethod.planning.startMoments))

            const postStartmomentsList = startMomentList.filter((startmoment) => !startmoment.id || startmoment.id === 'undefined')

            const putStartmomentsList = startMomentList.filter((startmoment) => startmoment.id)
            
            const deleteStartmomentsList = this.initialStartmoments.filter((startmoment) => startmoment.toBeDeleted)
       
            if(!postStartmomentsList && !deleteStartmomentsList && !putStartmomentsList) return

            deleteStartmomentsList.forEach((startmoment)=> {
                promises.push(deleteStartmoment(course.id, startmoment.id, this.$store))
            })

            await Promise.all(promises)

            promises = []
            postStartmomentsList.forEach((startmoment) => {
                formatStartMomentsBeforeSave(startmoment)
                formatStartmomentCutOfDateToUtcBeforeSave(course, startmoment)
                promises.push(postStartmoment(course.id, startmoment, this.$store))
            })

            await Promise.all(promises)
            promises = []
            putStartmomentsList.forEach((startmoment, index) => {
                const getMatchingOriginalStartmoment = this.initialStartmoments.find(initialStartmoment => initialStartmoment.id === startmoment.id)
                //Check for changes on startmoment array and do a put to that startmoment
                if(!this.startmomentHasChanges(startmoment, getMatchingOriginalStartmoment)) return null
                formatStartMomentsBeforeSave(startmoment)
                formatStartmomentCutOfDateToUtcBeforeSave(course, startmoment)
                
                if(startmoment.hasDetails) {
                    promises.push(putStartmoment(course.id, startmoment, this.$store))
                } else {
                    const objToPatch = this.setObjectToBePatched(startmoment, getMatchingOriginalStartmoment)
                    promises.push(patchStartmoment(course.id, startmoment.id, objToPatch, this.$store))
                }
            

               
            })

            await Promise.all(promises)
        },
        setInvoicesCourseValue(payload) {
            this.course[payload.key] = payload.value
        },
        setStartMoments(payload) {
            const action = payload.action
            if (action === 'all') {
                this.course.learningMethod.planning.startMoments = payload.startMomentItems
            } else if (action === 'add') {
                this.course.learningMethod.planning.startMoments.push(payload.emptyStartMoment)
                this.setPaginationOptions.totalCount = this.setPaginationOptions.totalCount + 1;
            } else if (action === 'remove') {
                this.course.learningMethod.planning.startMoments.splice(payload.startMomentIndex, 1)
                this.initialStartmoments.map(startmoment => {
                    if(startmoment.id === payload.id) startmoment.toBeDeleted = true
                    return startmoment
                })
                this.setPaginationOptions.totalCount = this.setPaginationOptions.totalCount - 1;
            } else if (action === 'setField') {
                this.course.learningMethod.planning.startMoments[payload.index][payload.field] = payload.value
            } else if (action === 'setStartDateField') {
                this.course.learningMethod.planning.startMoments[payload.index].startDate = payload.value
            } else if (action === 'setModuleField') {
                this.course.learningMethod.planning.startMoments[payload.startMomentIndex].modules[payload.moduleIndex][payload.field] = payload.value
            } else if (action === 'removeModule') {
                this.course.learningMethod.planning.startMoments[payload.startMomentIndex].modules.splice(payload.moduleIndex, 1)
            } else if (action === 'addModule') {
                this.course.learningMethod.planning.startMoments[payload.index].modules.push(payload.emptyModuleItem)
            } else if (action === 'setEventField') {
                this.course.learningMethod.planning.startMoments[payload.startMomentIndex].modules[payload.moduleIndex].events[payload.eventIndex][payload.field] = payload.value
            } else if (action === 'addEvent') {
                this.course.learningMethod.planning.startMoments[payload.startMomentIndex].modules[payload.moduleIndex].events.push(payload.value)
            } else if (action === 'removeEvent') {
                this.course.learningMethod.planning.startMoments[payload.startMomentIndex].modules[payload.moduleIndex].events.splice(payload.eventIndex, 1)
            } else if (action === 'addInvoice') {
                this.course.learningMethod.planning.startMoments[payload.startMomentIndex].invoiceItems.push(payload.value)
            } else if (action === 'setStudyloadValue') {
                if(!payload.value) {
                    this.course.learningMethod.studyLoad.unit = null
                }
                this.course.learningMethod.studyLoad.value = payload.value
            } else if (action === 'setStudyloadUnit') {
                this.course.learningMethod.studyLoad.unit = payload.value
            } else if (action === 'setDurationValue') {
                if(!payload.value) {
                    this.course.learningMethod.duration.unit = null
                }
                this.course.learningMethod.duration.value = payload.value
            } else if (action === 'setDurationUnit') {
                this.course.learningMethod.duration.unit = payload.value
            } else if (action === 'setContactSessions') {
                this.course.learningMethod.contactSessions = payload.value
            } else if (action === 'setLearningMethodField') {
                this.course.learningMethod[payload.key] = payload.value
            } else if (action === 'setStartmomentDetail') {
                const objDetails = { ...this.course.learningMethod.planning.startMoments[payload.index]}; // Start with a copy of startmoment obj

                for (const key in payload.startmomentDetails) {
                    if (payload.startmomentDetails.hasOwnProperty(key) && !this.course.learningMethod.planning.startMoments[payload.index].hasOwnProperty(key)) {
                        objDetails[key] = payload.startmomentDetails[key]; // Copy properties from payload that don't exist in startmoment
                    }
                }

                this.course.learningMethod.planning.startMoments[payload.index] = objDetails
            } else {
                console.error('Invalid action')
            }

            this.$forceUpdate()
        },
        startmomentHasChanges(startmoment, original) {
            // Remove properties added from the fe
            delete startmoment.hasErrors
            delete startmoment.isActive
            delete original.hasErrors
            delete original.isActive
            delete original.toBeDeleted

            return !shallowEqual(startmoment, original)
        },
        setObjectToBePatched(startmoment, originalStartmoment) {
            const patchProperties = Object.keys(startmoment)

            const propertiesToPatch = []

            patchProperties.map(property => {                 
                if (startmoment[property] !== originalStartmoment[property]) { 
                    return propertiesToPatch.push({
                        value: startmoment[property],
                        path: property,
                        op: 'replace'
                    })
                }
                return propertiesToPatch
            })
            return propertiesToPatch
        }
    }
}
</script>
